<template lang="pug">
.main-wrapper.caixas-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Transferências</b>

    Dialog.dialogApagar(header='Cancelar Transferência' :visible.sync='dialogApagar' :modal='true')
        p Deseja cancelar a transferência? <br><b>O valor retornará a origem.</b>
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')
    
    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-4
                label.form-label Tipo de transferência:
                .p-inputgroup
                    Dropdown(
                        placeholder='Selecione o Tipo...'
                        :options='options.tipoOption'
                        optionLabel='label'
                        optionValue='value'
                        @change='() => { filters.cd_origem = null; filters.cd_destino = null; getOrigem(); getDestino(); applyFilters();}'
                        v-model='filters.ie_tipo'
                        filter
                    )

            .p-col-12.p-md-4
                label.form-label Origem:
                ProgressBar(v-if='loading.origem' mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Origem...'
                        :options='options.origemOption'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_origem'
                        :disabled="options.origemOption.length == 0"
						v-tooltip.bottom="options.origemOption.length == 0 ? 'Informe o tipo de transferência antes' : ''"
                        filter
                    )

            .p-col-12.p-md-4
                label.form-label Destino:
                ProgressBar(v-if='loading.destino' mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Destino...'
                        :options='options.destinoOption'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_destino'
                        :disabled="options.destinoOption.length == 0"
						v-tooltip.bottom="options.destinoOption.length == 0 ? 'Informe o tipo de transferência antes' : ''"
                        filter
                    )

            .p-col-12.p-md-4
                label.form-label Conta Financeira:
                .p-inputgroup
                    Dropdown(
                        placeholder='Selecione Conta Financeira...'
                        :options='options.contaFinanceiraOption'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_plano_contas'
                        filter
                    )

            .p-col-12.p-md-2
                label.form-label Data Inicial:
                .p-inputgroup
                    Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button(icon='jam jam-rubber' @click="() => {filters.dt_inicial = null; applyFilters();}")

            .p-col-12.p-md-2
                label.form-label Data Final:
                .p-inputgroup
                    Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                    Button(icon='jam jam-rubber' @click="() => {filters.dt_final = null; applyFilters();}")

            .p-col-12.p-md-4
                Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')

    .ta-right.mt-4
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/transferencias/salvar/')")
        br
        br

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.nm_centro_custo" style='position: relative')
                        .ta-left
                            p <b>Tipo:</b> {{ dominio.tipo[props.data.ie_tipo] || props.data.ie_tipo}}
                        .ta-left
                            p <b>Origem:</b> {{ props.data.nm_origem }}
                        .ta-left    
                            p <b>Destino:</b> {{ props.data.nm_destino }}
                        .ta-left    
                            p <b>Valor:</b> {{formatPrice(props.data.nr_valor)}}
                        .ta-left    
                            p <b>Data:</b> {{ props.data.dt_transferencia_f }}
                        .ta-left    
                            p <b>Conta Financeira:</b> {{ props.data.nm_plano_contas }}
                        .ta-right
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="pi pi-external-link"
                                @click="$router.push(`/transferencias/salvar/${ props.data.id }/`)"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Transferências')
            DataTable(:value="list")
                Column(headerStyle='width: 8%;' field='ie_tipo' header='Tipo' )
                    template(#body='props')
                        span {{ dominio.tipo[props.data.ie_tipo] || props.data.ie_tipo }}
                Column(headerStyle='width: 15%;' field='nm_origem' header='Origem')
                Column(headerStyle='width: 15%;' field='nm_destino' header='Destino')
                Column(headerStyle='width: 10%;' field='nr_valor' header='Valor')
                    template(#body='props')
                        span {{formatPrice(props.data.nr_valor)}}
                Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='dt_transferencia_f' header='Data')
                Column(headerStyle='width: 15%;' field='nm_plano_contas' header='Conta Financeira')
                Column(headerStyle='width: 8%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Visualizar'"
                                icon="pi pi-external-link"
                                @click="$router.push(`/transferencias/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Cancelar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>
    
<style lang="scss">
    .caixas-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>
    
<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import InputNumber from 'primevue/inputnumber'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import Calendar from 'primevue/calendar'
    import SelectButton from 'primevue/selectbutton'
    import moment from 'moment'
    
    import { pCalendarLocale_ptbr } from './../../utils'
    import { Caixas, ContasBancarias, DominioValor, Transferencias, PlanoContas } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'transferencias') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
                vm.getOrigem()
                vm.getDestino()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () {
            this.getDominio()
            this.getContaFinanceira()
            // this.applyFilters() 
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputNumber, SelectButton,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown, Calendar},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingUnidades: false,
                dialogApagar: false,
                dialogApagar_data: {},
                ptbr: pCalendarLocale_ptbr,
                filters: {
                    ie_tipo: null,
                    cd_origem: null,
                    cd_destino: null,
                    cd_plano_contas: null,
                    dt_inicial: null,
                    dt_final: null,
                },
                options:{
					tipoOption: [],
                    origemOption: [],
                    destinoOption: [],
                    contaFinanceiraOption: [],
                },
                dominio: {
                    tipo: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                loading: {
					origem: false,
					destino: false,
				},
            }
        },
        methods: {
            resetFilters() {
				this.filters.ie_tipo = null
				this.filters.cd_origem = null
                this.filters.cd_destino = null
				this.filters.cd_plano_contas = null
				this.filters.dt_inicial = null
				this.filters.dt_final = null
			},
			limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
            getList (params) {
                this.waiting = true
                return Transferencias.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        response.data.results.forEach(item => {
                            item.dt_transferencia_f = moment(item.dt_transferencia).format('DD/MM/YYYY')
                        })
                        this.list = response.data.results
                        
                    }
                    this.waiting = false
                    return true
                })
            },
            getOrigem () {
                this.loading.origem = true
                this.options.origemOption = [{value: null, label: 'Selecione'}]
                // this.filters.cd_origem = null
                if(["BB", "BC"].includes(this.filters.ie_tipo)){
                    ContasBancarias.findAll().then(response => {
                        if (([200, 201]).includes(response.status)) {
                            response.data.forEach(i => {
                                this.options.origemOption.push({
                                    value: i.id,
                                    label: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                })
                            })
                        } else {
                            this.$toast.error("Houve um problema ao carregar origem.", { duration: 3000 });
                        }
                    })
                } else if(["CB", "CC"].includes(this.filters.ie_tipo)){
                    Caixas.findAll().then(response => {
                        if (([200, 201]).includes(response.status)) {
                            response.data.forEach(i => {
                                this.options.origemOption.push({
                                    value: i.id,
                                    label: i.nm_caixa,
                                })
                            })
                        } else {
                            this.$toast.error("Houve um problema ao carregar origem.", { duration: 3000 });
                        }
                    })
                }
                this.loading.origem = false
            },
            getDestino () {
                this.loading.destino = true
                this.options.destinoOption = [{value: null, label: 'Selecione'}]
                // this.filters.cd_destino = null
                if(["BB", "CB"].includes(this.filters.ie_tipo)){
                    ContasBancarias.findAll().then(response => {
                        if (([200, 201]).includes(response.status)) {
                            response.data.forEach(i => {
                                this.options.destinoOption.push({
                                    value: i.id,
                                    label: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                })
                            })
                        } else {
                            this.$toast.error("Houve um problema ao carregar destino.", { duration: 3000 });
                        }
                    })
                } else if(["BC", "CC"].includes(this.filters.ie_tipo)){
                    Caixas.findAll().then(response => {
                        if (([200, 201]).includes(response.status)) {
                            response.data.forEach(i => {
                                this.options.destinoOption.push({
                                    value: i.id,
                                    label: i.nm_caixa,
                                })
                            })
                        } else {
                            this.$toast.error("Houve um problema ao carregar destino.", { duration: 3000 });
                        }
                    })
                }
                this.loading.destino = false
            },
            getContaFinanceira () {
                PlanoContas.findAll({ie_tipo: 'A'}).then(response => {
                    this.options.contaFinanceiraOption = [{value: null, label: 'Selecione'}]
                    if (([200, 201]).includes(response.status)) {
                        response.data.forEach(i => {
                            this.options.contaFinanceiraOption.push({
                                value: i.id,
                                label: i.ds_descricao,
                            })
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar as contas financeiras.", { duration: 3000 });
                    }
                })
            },
            getDominio () {
                DominioValor.findAll({ds_mnemonico: 'TIPO_TRANSFERENCIA'}).then((response) => {
                    this.options.tipoOption.push({label: 'Selecione', value: null})
					if(response.status === 200) {
                        response.data['TIPO_TRANSFERENCIA'].valores.forEach(item => {
							this.options.tipoOption.push({label: item.ds_valor, value: item.ie_valor})
                            this.dominio.tipo[item.ie_valor] = item.ds_valor
						})
					}
				})
            },
            formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if ((this.filters[key])) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).utcOffset('-03:00').format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                Transferencias.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Transferência cancelada com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>